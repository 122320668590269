import { ILocation } from '@wix/yoshi-flow-editor';

// dynamicUrlPart returns /dataset-name/item-name. We only should need item-name
// to be truly dynamic!
export const getDynamicSection = (location: ILocation) => {
  const dynamicUrlPart = location.url.replace(location.baseUrl, '').split('?')[0];
  const endsWithSlash = dynamicUrlPart.endsWith('/');

  const dynamicUrlSplit = dynamicUrlPart.split('/');
  return endsWithSlash
    ? dynamicUrlSplit[dynamicUrlSplit.length - 2]
    : dynamicUrlSplit[dynamicUrlSplit.length - 1];
};
