import { CreateControllerFn } from '@wix/yoshi-flow-editor';
import { getCtxFields } from './controller/get-ctx-fields';
import { resolvePaginationConfig } from './configuration/resolve-pagination-config';
import { isWithVotes } from './configuration/is-with-votes';
import settingsParams from './settingsParams';
import { createSettingsEventHandler } from '~common/settings-events/create-settings-events-handler';
import { isWithEmotions } from './configuration/is-with-emotions';
import { getIsBlogComments } from './controller/get-is-blog-comments';
import { createCommentsControllerFlow } from './controller/create-comments-flow';
import { initCommentsController } from './controller/init-comments-controller';
import { getIsDemoMode } from './controller/is-demo-mode';
import { createWidgetStore } from './controller/widget-store';
import { BLOG_APP_DEF_ID } from '~/constants/app-def-ids';
import { createPublicApi } from './controller/public-api/public-api';
import { getIsDeepLinked } from './Widget/utils/get-is-deep-linked';
import { createCommentsApiHolder } from './create-comments-api-holder';

const createController: CreateControllerFn = async ({ flowAPI }) => {
  const { setProps, config, wixCodeApi, appParams } = flowAPI.controllerConfig;
  const commentsApiHolder = createCommentsApiHolder();
  const settingsEventHandler = createSettingsEventHandler(config.publicData.COMPONENT);
  const isBlogComments = getIsBlogComments(appParams.appDefinitionId);
  const isDemoMode = getIsDemoMode(flowAPI);
  const widgetStore = createWidgetStore({
    isLocked: false,
    resourceId: undefined,
    category: undefined,
  });

  const commentsFlow = await createCommentsControllerFlow({
    flowAPI,
    isDemoMode,
    isBlogComments,
    widgetStore,
  });

  if (process.env.NODE_ENV === 'test') {
    // currently, testkits do not allow accessing controller exports,
    // this exposes the public API to the window global which is available only test env

    // @ts-expect-error
    window.commentsPublicApi = createPublicApi({ widgetStore, commentsApiHolder });
    // @ts-expect-error
    window.widgetStore = widgetStore;
  }

  return {
    async pageReady() {
      commentsFlow.type === 'BlogFlow' && (await widgetStore.waitForSetResourceId());
      const locationQuery = wixCodeApi.location.query;

      const widgetState = widgetStore.getState();

      const commentsApi = await initCommentsController(flowAPI, {
        enableWarmupDataCache: flowAPI.experiments.enabled('specs.wixComments.useWarmupData'),
        isDemoMode,
        appDefinitionId: isBlogComments ? BLOG_APP_DEF_ID : appParams.appDefinitionId,
      });
      commentsApiHolder.setApi(commentsApi);

      const fetchComments = async () => {
        const { resourceId, category } = widgetStore.getState();
        if (!resourceId) {
          return;
        }
        // fetchComments type shows that it returns void, but in fact, it returns a promise
        await commentsApi.fetchComments({
          resourceId,
          ctxFields: getCtxFields({
            isBlogComments,
            resourceId,
            categoryId: category?.id,
          }),
          pagination: resolvePaginationConfig({
            settings: {
              sortCommentsBy: flowAPI.settings.get(settingsParams.sortCommentsBy),
              repliesOnLoadMore: flowAPI.settings.get(settingsParams.repliesOnLoadMore),
              commentsOnLoadMore: flowAPI.settings.get(settingsParams.commentsOnLoadMore),
              commentsOnFirstPage: flowAPI.settings.get(settingsParams.commentsOnFirstPage),
              repliesOnFirstPage: flowAPI.settings.get(settingsParams.repliesOnFirstPage),
            },
            withVotes: category ? isWithVotes(category?.reactionType) : false,
            withEmotions: category ? isWithEmotions(category?.reactionType) : false,
          }),
          withVotes: category ? isWithVotes(category?.reactionType) : false,
          withRatings: category ? category?.ratingsSettings?.ratingsEnabled : false,
        });
      };

      const fetchDeepLinkComments = async ({ commentId }: { commentId: string }) => {
        const { resourceId, category } = widgetStore.getState();
        if (!resourceId) {
          return;
        }
        // fetchDeepLink type shows that it returns void, but in fact, it returns a promise
        await commentsApi.fetchDeepLink({
          commentId,
          resourceId,
          ctxFields: getCtxFields({
            isBlogComments,
            resourceId,
            categoryId: category?.id,
          }),
          pagination: resolvePaginationConfig({
            settings: {
              sortCommentsBy: flowAPI.settings.get(settingsParams.sortCommentsBy),
              repliesOnLoadMore: flowAPI.settings.get(settingsParams.repliesOnLoadMore),
              commentsOnLoadMore: flowAPI.settings.get(settingsParams.commentsOnLoadMore),
              commentsOnFirstPage: flowAPI.settings.get(settingsParams.commentsOnFirstPage),
              repliesOnFirstPage: flowAPI.settings.get(settingsParams.repliesOnFirstPage),
            },
            withVotes: category ? isWithVotes(category?.reactionType) : false,
            withEmotions: category ? isWithEmotions(category?.reactionType) : false,
          }),
          withVotes: category ? isWithVotes(category?.reactionType) : false,
          withRatings: category ? category?.ratingsSettings?.ratingsEnabled : false,
        });
      };

      settingsEventHandler
        .on('server-settings-updated', async () => {
          const category = await commentsFlow.fetchCategory();
          category && widgetStore.setState({ category });
        })
        .on('view-open-box-settings', async () => {
          const { resourceId } = widgetStore.getState();
          resourceId &&
            commentsApi.affect.tryOpenCommentBox(resourceId, {
              shouldFocus: false,
              shouldScroll: false,
            });
        })
        .on(['view-closed-box-settings', 'exit-open-box-settings'], async () => {
          const { resourceId } = widgetStore.getState();
          resourceId && commentsApi.affect.tryCloseAllCommentBoxes(resourceId);
        })
        .on(['sort-updated', 'pagination-updated'], async () => {
          fetchComments();
        })
        .on(
          ['login-button-toggle', 'comments-replies-settings-updated', 'text-settings-updated'],
          () => {
            setProps({ rootKey: Date.now().toString() });
          },
        );

      widgetStore.subscribe((newState) => {
        if (newState.resourceId) {
          fetchComments();
        }
        setProps(newState);
      });

      getIsDeepLinked(locationQuery, widgetState.resourceId)
        ? await fetchDeepLinkComments({ commentId: locationQuery.commentId })
        : await fetchComments();

      setProps({
        ctxFields: getCtxFields({
          isBlogComments,
          resourceId: widgetState.resourceId,
          categoryId: widgetState.category?.id,
        }),
        isLocked: widgetState.isLocked,
        resourceId: widgetState.resourceId,
        category: widgetState.category,
        devSettingsConfig: {
          forceDoubleCommentBox: !!locationQuery?.forceDoubleCommentBox ?? false,
          screenshotMode: !!locationQuery?.screenshotMode ?? false,
        },
        fitToContentHeight: true,
        isDemoMode,
      });

      commentsApi.bindStateToSetProps();
    },
    updateConfig: (_, { publicData }) => {
      settingsEventHandler.updateData(publicData.COMPONENT ?? {});
    },
    exports: () =>
      createPublicApi({
        widgetStore,
        commentsApiHolder,
      }),
  };
};

export default createController;
