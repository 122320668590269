import { CommentDescriptor } from '@wix/comments-ooi-client/controller';
import { RichContent } from '@wix/ricos-editor';
import { WidgetComment, PublicSettings } from './public-api.types';
import { Category } from '~/services/category-service';

export const toPublicApiComment = (
  comment: CommentDescriptor,
  richContentToString: (content: RichContent) => string,
): WidgetComment => {
  return {
    type: comment.type,
    commentId: comment.commentId!,
    createdDate: comment.createdAt,
    parentCommentId: comment.type === 'REPLY' ? comment.parentId : undefined,
    content: comment.content.richContent
      ? richContentToString(comment.content.richContent)
      : undefined,
    authorName: comment.author
      ? comment.author.type === 'MEMBER'
        ? comment.author.profile.nickname ?? undefined
        : 'Guest'
      : undefined,
  };
};

export const toPublicSettings = (category: Category): PublicSettings => {
  return {
    ratings: {
      isEnabled: category.ratingsSettings?.ratingsEnabled ?? false,
      isRequired: category.ratingsSettings?.ratingRequired ?? false,
    },
    permissions: {
      guest: {
        canComment: category.guestCommenting ?? false,
        canReply: category.guestCommenting ?? false,
        canReact: category.guestReactions ?? false,
      },
    },
  };
};
